.x-tabs
  +preset(1)
  display: block
  white-space: nowrap
  position: relative
  &:after
    content: ""
    display: block
    position: absolute
    left: 0
    right: 0
    bottom: 0
    height: 1px
    z-index: 1
    background-color: var(--divider-dark-grey)
  &__container
    display: flex
    align-items: flex-start
    justify-content: space-between
  &__scrollable
    min-width: 0
    overflow: hidden
    &:not(:last-child)
      margin-right: 16px
    &-content
      display: inline-grid
      grid-gap: 24px
      grid-auto-flow: column
  &__item
    color: var(--on-button-dark)
    padding-bottom: 15px
    border-bottom: 2px solid rgba(255, 255, 255, 0)
    &,
    a
      display: inline-grid
      align-items: center
      grid-gap: 6px
      grid-auto-flow: column
      cursor: pointer
    a
      color: currentColor
    img
      flex: 0 0 auto
      width: 16px
      height: 16px
    &-important
      flex: 0 0 auto
      width: 6px
      height: 6px
      background-color: var(--icon-red)
      border-radius: 50%
    &[selected]
      position: relative
      z-index: 2
      color: var(--on-button-active-green)
      border-bottom-color: var(--on-button-active-green)
    &:hover
      color: var(--on-button-active-green)
