import { Component, Host, Prop, Element, Fragment, h } from '@stencil/core';
// import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';
import axios from 'axios';
import { paginationStore } from '../../stores';

@Component({
  tag: 'x-tabs',
  styleUrl: 'x-tabs.sass',
})
export class XTabs {
  @Element() el: HTMLElement;

  @Prop() container: boolean;
  @Prop() item: boolean;
  @Prop({ mutable: true, reflect: true }) selected: boolean;
  @Prop() important: boolean;
  @Prop() startIcon: string;
  @Prop() endIcon: string;
  @Prop() value: string;
  @Prop() endpoint: string;
  @Prop() href: string;

  scrollableRef!: HTMLDivElement;

  private switchTab = () => {
    if (this.selected) {
      return;
    }

    const container = this.el.closest('x-tabs[container]');
    const endpoint = container.getAttribute('endpoint');

    paginationStore.set('isLoading', true);

    axios
      .put(endpoint, this.value, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        document.querySelector('x-pagination-context[target]').innerHTML = res.data;

        this.el.parentNode.querySelector('[selected]').removeAttribute('selected');
        this.selected = true;

        const nextCurrent = res.headers['x-current-page'];
        const nextSize = res.headers['x-page-size'];
        const nextTotal = res.headers['x-total-pages'];
        const nextTotalRecords = res.headers['x-total-records'];

        if (nextCurrent) paginationStore.set('currentPage', parseInt(nextCurrent, 10));
        if (nextSize) paginationStore.set('pageSize', parseInt(nextSize, 10));
        if (nextTotal) paginationStore.set('totalPages', parseInt(nextTotal, 10));
        if (nextTotalRecords) paginationStore.set('totalRecords', parseInt(nextTotalRecords, 10));
      })
      .finally(() => {
        paginationStore.set('isLoading', false);
      });
  };

  componentDidLoad() {
    if (this.container) {
      OverlayScrollbars(this.scrollableRef, {
        overflow: {
          y: 'hidden',
        },
      });
    }
  }

  render() {
    if (this.container) {
      return (
        <Host class="x-tabs">
          <div class="x-tabs__container">
            <div class="x-tabs__scrollable" ref={el => (this.scrollableRef = el)}>
              <div class="x-tabs__scrollable-content">
                <slot></slot>
              </div>
            </div>
            <slot name="adornment"></slot>
          </div>
        </Host>
      );
    } else if (this.item) {
      const tabItemBody = (
        <Fragment>
          {this.startIcon && <img src={this.startIcon} alt="" />}
          <span>
            <slot></slot>
          </span>
          {this.endIcon && <img src={this.endIcon} alt="" />}
          {this.important && <div class="x-tabs__item-important"></div>}
        </Fragment>
      );

      return (
        <Host class="x-tabs__item" onClick={!this.href ? this.switchTab : null}>
          {!this.href ? tabItemBody : <a href={this.href}>{tabItemBody}</a>}
        </Host>
      );
    } else {
      return null;
    }
  }
}
